import { type LocalePathFunction } from 'vue-i18n-routing';

const getContentCardConfig = (localePath: LocalePathFunction) => {
  // Promotion for tick prescription on cat food categories and dog food categories
  const contentCardConfigTickPrescription = {
    showForAttributes: [
      {
        name: 'categoriesPath',
        value: 'djurtyp/katt/kattfoder/vatfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/katt/kattfoder/veterinarfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/veterinarfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/vatfoder',
      },
    ],
    name: 'tickPrescriptionFreeShipping',
    image: {
      desktop: 'tick-prescriptions-desktop.webp',
      mobile: 'tick-prescriptions-mobile.webp',
    },
    link: localePath('/tjanster/veterinar/fastingmedel'),
  };

  const contentCardConfigHills = {
    showForAttributes: [
      {
        name: 'categoriesPath',
        value: 'djurtyp/katt/kattfoder/torrfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/torrfoder',
      },
    ],
    name: 'tickPrescriptionFreeShipping',
    image: {
      desktop: 'hills-desktop.webp',
      mobile: 'hills-mobile.webp',
    },
    link: localePath(
      '/butik/varumarken/hills?Egenskaper=Normal&Konsistens=Torrfoder'
    ),
  };

  // Promotion for prescriptions on pharmacy category
  const contentCardConfigPharmacy = {
    showForAttributes: [
      {
        name: 'categoriesPath',
        value: 'djurtyp/apotek',
      },
    ],
    name: 'prescriptions',
    image: {
      desktop: 'prescriptions-desktop.webp',
      mobile: 'prescriptions-mobile.webp',
    },
    link: localePath({ name: 'shop-login' }),
  };

  return {
    sv: [
      contentCardConfigTickPrescription,
      contentCardConfigPharmacy,
      contentCardConfigHills,
    ],
  };
};

export { getContentCardConfig };
